import React, { useState } from 'react';
import './NavBar.css';
import logo from './favicon.svg'; // replace with your logo path

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="navbar">
      <div className="logo-container">
      <a href="/"><img src={logo} alt="Club Logo" className="logo"/> <span className="logo-text">Malahide Chess Club</span></a>
      </div>
      <div onClick={() => setIsOpen(!isOpen)} className="burger-menu">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul className={`navbar-nav ${isOpen ? 'show-nav' : ''}`}>
        <li className="nav-item"><a href="/" className="nav-link">Home</a></li>
        <li className="nav-item"><a href="/about" className="nav-link">Join</a></li>
        <li className="nav-item"><a href="/history" className="nav-link">History</a></li>
        <li className="nav-item"><a href="/events" className="nav-link">Events</a></li>
      </ul>
    </nav>
  );
}

export default NavBar;