// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.schedule {
    font-size: 18px;
    line-height: 1.6;
}

.day {
    font-weight: bold;
    color: #333;
}

.time {
    font-style: italic;
    color: #666;
}

.juniors {
    color: #000000;
}

.seniors {
    color: #000000;
}

.location {
    margin-top: 20px;
    font-size: 18px;
}

.location a {
    color: #007BFF;
    text-decoration: none;
}

.location a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/Home.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".home-container {\n    width: 100%;\n    padding: 20px;\n    box-sizing: border-box;\n}\n\n.schedule {\n    font-size: 18px;\n    line-height: 1.6;\n}\n\n.day {\n    font-weight: bold;\n    color: #333;\n}\n\n.time {\n    font-style: italic;\n    color: #666;\n}\n\n.juniors {\n    color: #000000;\n}\n\n.seniors {\n    color: #000000;\n}\n\n.location {\n    margin-top: 20px;\n    font-size: 18px;\n}\n\n.location a {\n    color: #007BFF;\n    text-decoration: none;\n}\n\n.location a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
