import './Home.css';
import React from 'react';

const Home = () => {
    return (
        <div className="home-container">
            <p className="schedule">
                <strong className="day">We Meet On Monday:</strong><br />
                <em className="time juniors">Juniors:</em> From 18:30 to 19:30.<br />
                <em className="time seniors">Seniors:</em> From 20:00 onwards.
            </p>
            <p className="location">
                <strong>Location:</strong> <a href="https://maps.google.com/maps/dir//St.+Sylvester%E2%80%99s+GAA+Club+2+Church+Rd+Malahide+Co.+Dublin,+K36+EW81/@53.45016,-6.1536416,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x48671a6042f5a673:0xecb47932c0c884c7" target="_blank" rel="noopener noreferrer">St. Sylvester’s GAA Club, Malahide</a><br />
                <em>Club Room is on the top floor</em>
            </p>
            <div className="map">
                <iframe title="Google Maps location of St. Sylvester’s GAA Club, Malahide"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2381.919019178688!2d-6.15583028423039!3d53.45015997998177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48671a6042f5a673%3A0xecb47932c0c884c7!2sSt.%20Sylvester's%20GAA%20Club!5e0!3m2!1sen!2sie!4v1637672821234!5m2!1sen!2sie" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>
    );
}

export default Home;