// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #bbdefb;
    padding: 20px;
    box-sizing: border-box;
}

.about-page div {
    text-align: center;
    max-width: 800px;
}

.about-page h1 {
    font-size: 2.5em;
    color: #333;
}

.about-page p {
    font-size: 1.2em;
    color: #666;
}

.email {
    font-size: 1.5em;
    color: #007BFF;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/About.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;AACnB","sourcesContent":[".about-page {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #bbdefb;\n    padding: 20px;\n    box-sizing: border-box;\n}\n\n.about-page div {\n    text-align: center;\n    max-width: 800px;\n}\n\n.about-page h1 {\n    font-size: 2.5em;\n    color: #333;\n}\n\n.about-page p {\n    font-size: 1.2em;\n    color: #666;\n}\n\n.email {\n    font-size: 1.5em;\n    color: #007BFF;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
