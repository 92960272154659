// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events-page {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.past-events, .future-events {
    width: 30%;  /* Adjust as needed */
}

.App-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;  /* Adjust as needed */
}

.App-logo {
    height: 40px;  /* Adjust as needed */
}`, "",{"version":3,"sources":["webpack://./src/History.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,UAAU,GAAG,qBAAqB;AACtC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU,GAAG,qBAAqB;AACtC;;AAEA;IACI,YAAY,GAAG,qBAAqB;AACxC","sourcesContent":[".events-page {\n    display: flex;\n    justify-content: space-between;\n    padding: 20px;\n}\n\n.past-events, .future-events {\n    width: 30%;  /* Adjust as needed */\n}\n\n.App-header {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 30%;  /* Adjust as needed */\n}\n\n.App-logo {\n    height: 40px;  /* Adjust as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
