// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS: Events.css */
.events h1 {
    text-align: center;
}

.events ul {
    list-style-type: none;
    padding: 0;
}

.events li {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
}

.events h2 {
    margin: 0;
}

.events p {
    margin: 10px 0;
}

.events a {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Events.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;AACzB","sourcesContent":["/* CSS: Events.css */\n.events h1 {\n    text-align: center;\n}\n\n.events ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.events li {\n    margin: 20px;\n    padding: 20px;\n    border: 1px solid #ccc;\n}\n\n.events h2 {\n    margin: 0;\n}\n\n.events p {\n    margin: 10px 0;\n}\n\n.events a {\n    display: inline-block;\n    margin-top: 10px;\n    padding: 10px 20px;\n    background-color: #007BFF;\n    color: white;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
