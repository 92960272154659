// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:  #1565c0;;
  padding: 0 20px;
}

.logo-text {
  color: black;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px; /* adjust as needed */
}

.burger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.burger-menu div {
  width: 2rem;
  height: 0.25rem;
  background-color: #fff;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 10px;
}

.nav-link {
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.nav-link:hover {
  background-color: #ddd;
  color: black;
}

/* Responsive styling */
@media (max-width: 600px) {
  .navbar-nav {
    flex-direction: column;
    width: 100%;
    display: none;
  }

  .navbar-nav.show-nav {
    display: flex;
  }

  .nav-item {
    margin: 10px 0;
  }

  .burger-menu {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./src/NavBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY,EAAE,qBAAqB;AACrC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA,uBAAuB;AACvB;EACE;IACE,sBAAsB;IACtB,WAAW;IACX,aAAa;EACf;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".navbar {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color:  #1565c0;;\n  padding: 0 20px;\n}\n\n.logo-text {\n  color: black;\n}\n\n.logo-container {\n  display: flex;\n  align-items: center;\n}\n\n.logo {\n  height: 50px; /* adjust as needed */\n}\n\n.burger-menu {\n  display: none;\n  flex-direction: column;\n  justify-content: space-around;\n  width: 2rem;\n  height: 2rem;\n  cursor: pointer;\n}\n\n.burger-menu div {\n  width: 2rem;\n  height: 0.25rem;\n  background-color: #fff;\n}\n\n.navbar-nav {\n  display: flex;\n  justify-content: center;\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n\n.nav-item {\n  margin: 0 10px;\n}\n\n.nav-link {\n  display: block;\n  color: #f2f2f2;\n  text-align: center;\n  padding: 14px 16px;\n  text-decoration: none;\n}\n\n.nav-link:hover {\n  background-color: #ddd;\n  color: black;\n}\n\n/* Responsive styling */\n@media (max-width: 600px) {\n  .navbar-nav {\n    flex-direction: column;\n    width: 100%;\n    display: none;\n  }\n\n  .navbar-nav.show-nav {\n    display: flex;\n  }\n\n  .nav-item {\n    margin: 10px 0;\n  }\n\n  .burger-menu {\n    display: flex;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
