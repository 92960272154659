// JavaScript: Events.js
import React from 'react';
import './Events.css';

const events = [
    {
        name: 'THE MALAHIDE MILLENNIUM TOURNAMENT',
        subheading: 'INCORPORATING THE 2024 LEINSTER CHAMPIONSHIPS SUPPORTED BY THE LEINSTER CHESS UNION',
        date: 'SAT 4TH, SUN 5TH & MON 6TH MAY 2024 ',
        flyerURL: 'https://www.icu.ie/system/events/flyers/000/001/682/original/MMT_2024_Flyerb.pdf?1710106772'
    }
];

const Events = () => (
    <div className="events">
        <h1>Upcoming Events</h1>
        <ul>
            {events.map((event, index) => (
                <li key={index}>
                    <h2>{event.name}</h2>
                    <p>{event.date}</p>
                    <p style={{ color: '#34495e', fontSize: '16px' }}>{event.subheading}</p>
                    <a href={event.flyerURL} target="_blank" rel="noopener noreferrer">View Flyer</a>
                </li>
            ))}
        </ul>
    </div>
);

export default Events;