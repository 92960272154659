import React from 'react';
import './History.css';
import logo from './favicon.svg';
import { Link } from 'react-router-dom';

const History = () => {
    // Replace these with your actual data
    const pastEvents = ['Malahide Millenium Past Results',];

    return (
        <div className="events-page">
            <div className="past-events">
                <Link to="/past">
                    <h2>Past Events</h2>
                </Link>
                {/* {pastEvents.map(event => <p key={event}><a href={`/${event}`}>{event}</a></p>)} */}
            </div>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <code>Malahide Chess Club </code>
            </header>
            <div className="future-events">
                <Link to="/events">
                    <h2>Future Events</h2>
                </Link>
            </div>
        </div>
    );
}

export default History;