import './App.css';
import NavBar from './NavBar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './About';
import History from './History';
import Home from './Home';
import Events from './Events';
import Past from './Past';

function App() {
  return (
    <div className="App">

      <Router>
        <NavBar />
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/history" element={<History />} />
          <Route path="/events" element={<Events />} />
          <Route path="/past" element={<Past />} />

          {/* other routes here */}
        </Routes>
      </Router>

    </div>
  );
}

export default App;
