import React from 'react';
// copy events css
import './Events.css';

// 2017 and 2008 results not available
let legacyEventHistory = [];

for (let year = 2000; year <= 2018; year++) {
    if (year === 2008 || year === 2017){

    }
    else if (year === 2004 || year === 2005 || year === 2006 || year === 2007 || year === 2009 || year === 2010 || year === 2011) {
        legacyEventHistory.push({
            name: `History of the malahide millenium tournament ${year} challengers`,
            date: 'History',
            flyerURL: `https://raw.githubusercontent.com/soggyfox/soggyfox.github.io/main/results/${year}/challengers/result.html`
        });
        legacyEventHistory.push({
            name: `History of the malahide millenium tournament ${year} major`,
            date: 'History',
            flyerURL: `https://raw.githubusercontent.com/soggyfox/soggyfox.github.io/main/results/${year}/major/result.html`
        });
    }
    else if (year === 2012 || year === 2013 || year === 2014 || year === 2015 || year === 2016 || year === 2017 || year === 2018) {
        legacyEventHistory.push({
            name: `History of the malahide millenium tournament ${year} junior`,
            date: 'History',
            flyerURL: `https://raw.githubusercontent.com/soggyfox/soggyfox.github.io/main/results/${year}/junior/result.html`
        });
        legacyEventHistory.push({
            name: `History of the malahide millenium tournament ${year} intermediate`,
            date: 'History',
            flyerURL: `https://raw.githubusercontent.com/soggyfox/soggyfox.github.io/main/results/${year}/intermediate/result.html`
        });
        legacyEventHistory.push({
            name: `History of the malahide millenium tournament ${year} senior`,
            date: 'History',
            flyerURL: `https://raw.githubusercontent.com/soggyfox/soggyfox.github.io/main/results/${year}/senior/result.html`
        });
    }
    else if (year === 2003) {
        legacyEventHistory.push({
            name: `History of the malahide millenium tournament ${year} under1400`,
            date: 'History',
            flyerURL: `https://raw.githubusercontent.com/soggyfox/soggyfox.github.io/main/results/${year}/under1400/result.html`
        });
        legacyEventHistory.push({
            name: `History of the malahide millenium tournament ${year} under1800`,
            date: 'History',
            flyerURL: `https://raw.githubusercontent.com/soggyfox/soggyfox.github.io/main/results/${year}/under1800/result.html`
        });
        legacyEventHistory.push({
            name: `History of the malahide millenium tournament ${year} Open`,
            date: 'History',
            flyerURL: `https://raw.githubusercontent.com/soggyfox/soggyfox.github.io/main/results/${year}/open/result.html`
        });
    } else {
        legacyEventHistory.push({
            name: `History of the malahide millenium tournament ${year} Open`,
            date: 'History',
            flyerURL: `https://raw.githubusercontent.com/soggyfox/soggyfox.github.io/main/results/${year}/open/result.html`
        });
        legacyEventHistory.push({
            name: `History of the malahide millenium tournament ${year} under1600`,
            date: 'History',
            flyerURL: `https://raw.githubusercontent.com/soggyfox/soggyfox.github.io/main/results/${year}/under1600/result.html`
        });
    }
}

legacyEventHistory = legacyEventHistory.reverse();
const Past = () => (
    <div className="events">
        <h1>Past Event History</h1>
        <ul>
            {legacyEventHistory.map((event, index) => (
                <li key={index}>
                    <h2>{event.name}</h2>
                    <p>{event.date}</p>
                    <a
                        href={event.flyerURL}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'white', textDecoration: 'none' }}
                    >
                        View History
                    </a>
                </li>
            ))}
        </ul>
    </div>
);

export default Past;