import React from 'react';
import './About.css';

const About = () => {
    const copyEmail = () => {
        const el = document.createElement('textarea');
        el.value = 'mhullachidechess@gmail.com';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert('Email copied to clipboard!');
    }

    return (
        <div className="about-page">
            <div>
                <h1>You are welcome to join our club</h1>
                <p>For more information contact at <span className="email" onClick={copyEmail}>vincentbissett@hotmail.com</span></p>
                Or send a text at 0861053202

            </div>
        </div>
    );
}

export default About;